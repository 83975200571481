import React, { useEffect, useState, useRef } from "react";
import copy from '../copy.svg';
import Footer from "../../../universal/footer";
import '../../../universal/payment.css';
import '../../../universal/buttons.css'
import useDelayedHeightCalculation from "../../../universal/useDelayedHeightCalculation";
import Timer from "../../../universal/timer";

const Payment = ({ navbarHeight }) => {
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState('lifetime'); // Default is lifetime
    const [priceInUSDT, setPriceInUSDT] = useState(119);
    const [oldPrice, setOldPrice] = useState(499); // Old prices based on the plan

    const section1Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);

    const section1Height = useDelayedHeightCalculation(section1Ref, 100); // 100ms delay, you can adjust this

    useEffect(() => {
        setBgHeight(section1Height + navbarHeight);
    }, [navbarHeight, section1Height]);

    useEffect(() => {
        // Update the prices based on the selected payment plan
        switch (selectedPaymentPlan) {
            case 'monthly':
                setPriceInUSDT(29);
                setOldPrice(129);
                break;
            case '6-month':
                setPriceInUSDT(79);
                setOldPrice(289);
                break;
            case 'lifetime':
            default:
                setPriceInUSDT(119);
                setOldPrice(499);
                break;
        }
    }, [selectedPaymentPlan]);

    const handleToggle = (paymentPlan) => {
        setSelectedPaymentPlan(paymentPlan);
    };

    const handleCopyToClipboard = () => {
        const addressText = document.getElementById('address').innerText;
        navigator.clipboard.writeText(addressText).then(() => {
            alert('Address copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const paymentInfo = {
        USDT: {
            address: 'TEPJARNvzP9yCWfDhpkNeXm8dUaGg6P8f9',
            qrCode: '../.././assets/images/services/vip/USDT.JPG',
            text: 'SEND USDT ONLY',
            network: 'NETWORK: TRC20'
        }
    };

    return (
        <div className="main-content">
            <img
                src='../.././assets/images/backgrounds/bg1.png'
                className='payment-section1-bg'
                style={{ height: `${bgHeight}px` }}
                alt="Background"
            />
            <div className="payment-main">
                <div className="payment-section1" ref={section1Ref}>
                    <h1 className="payment-title">LIMITED <span style={{color: '#c80170'}}>70% DISCOUNT</span></h1>
                    <div><Timer/></div>
                    <div className="payment-options">
                        <div className="toggle-group">
                            <button
                                className={`toggle-btn ${selectedPaymentPlan === 'monthly' ? 'toggled' : ''}`}
                                onClick={() => handleToggle('monthly')}
                            >
                                <div className="thumb"></div>
                            </button>
                            <label>Monthly</label>
                        </div>
                        <div className="toggle-group">
                            <button
                                className={`toggle-btn ${selectedPaymentPlan === '6-month' ? 'toggled' : ''}`}
                                onClick={() => handleToggle('6-month')}
                            >
                                <div className="thumb"></div>
                            </button>
                            <label>6 Months</label>
                        </div>
                        <div className="toggle-group">
                            <button
                                className={`toggle-btn ${selectedPaymentPlan === 'lifetime' ? 'toggled' : ''}`}
                                onClick={() => handleToggle('lifetime')}
                            >
                                <div className="thumb"></div>
                            </button>
                            <label>Lifetime</label>
                        </div>
                    </div>

                    <div className="payment-details">
                        <div className="payment-details-glass">
                            <h1 className="membership-name">{selectedPaymentPlan.toUpperCase()} VIP</h1>
                            {/* Display the old price with a line-through */}
                            <h2 className="membership-price-old" style={{ textDecoration: 'line-through', color: '#c80170' }}>
                                ${oldPrice}
                            </h2>
                            {/* Display the new price */}
                            <h2 className="membership-price" style={{color:'#fff', marginBottom: '3vh'}}>
                                ${priceInUSDT}
                            </h2>
                            <img src={paymentInfo.USDT.qrCode} alt="USDT QR Code" className="payment-qr" />
                            <div className="payment-details-address">
                                <p id="address">{paymentInfo.USDT.address}</p>
                            </div>
                            <button onClick={handleCopyToClipboard}>
                                <img src={copy} alt="copy icon" className="payment-copy-icon" />COPY
                            </button>
                            <div className="payment-subheader">
                                <h2>{paymentInfo.USDT.text}</h2>
                                <div className="payment-divider"></div>
                                <h2>{paymentInfo.USDT.network}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="payment-section2">
                    <h1>REDEEM YOUR MEMBERSHIP</h1>
                    <h2>SEND A SCREENSHOT OF YOUR PAYMENT</h2>
                    <a href="https://t.me/cryptoxnftsignals" target="blank"><button className="button-dark">SEND</button></a>
                </div>
            </div>
            <div className="footer"><Footer /></div>
        </div>
    );
};

export default Payment;
