import { useState, useEffect } from 'react';
import './timer.css'

const TimerLead = () => {
    const calculateTimeLeft = () => {
        // Get the current time in UTC
        const now = new Date();

        // Get the next Wednesday at 12:00 noon UTC
        const nextWednesday = new Date(now);
        nextWednesday.setUTCDate(now.getUTCDate() + ((3 + 7 - now.getUTCDay()) % 7)); // Set to next Wednesday
        nextWednesday.setUTCHours(12, 0, 0, 0); // Set to 12:00 noon UTC

        // If it's already past 12 noon on Wednesday, move to the next Wednesday
        if (now > nextWednesday) {
            nextWednesday.setUTCDate(nextWednesday.getUTCDate() + 7); // Move to the next Wednesday
        }

        // Calculate the difference in milliseconds
        const timeDifference = nextWednesday - now;

        // Convert milliseconds to seconds
        return Math.floor(timeDifference / 1000); // Return as seconds
    };

    // State for time left until the next Wednesday at 12:00 UTC
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        // Function to update the timer every second
        const tick = () => {
            setTimeLeft(prevTime => {
                if (prevTime <= 0) {
                    return calculateTimeLeft(); // Reset the time at the next Wednesday
                }
                return prevTime - 1; // Decrease by 1 second
            });
        };

        // Set an interval to call `tick` every second
        const timerId = setInterval(tick, 1000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(timerId);
    }, []);

    // Convert timeLeft (in seconds) to days, hours, minutes, and seconds
    const days = Math.floor(timeLeft / (3600 * 24));
    const hours = Math.floor((timeLeft % (3600 * 24)) / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    // Format the timer with leading zeros
    const formatTime = (time) => (time < 10 ? `0${time}` : time);

    return (
        <div>
            <div className='timer'>
                {days > 0 && `${days}D `}{formatTime(hours)}H {formatTime(minutes)}M {formatTime(seconds)}S
            </div>
        </div>
    );
};

export default TimerLead;
